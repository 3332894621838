import { Attr, Model, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { GiftCard } from '@/models/index'

@Model()
export default class GiftCardDiscount extends ApplicationRecord {
  static jsonapiType = 'gift_card_discounts'

  @Attr() discount!: number

  @BelongsTo() giftCard!: GiftCard
}
