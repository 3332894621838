import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { MultiChoiceGiftCard } from '@/models/index'

@Model()
export default class DotationOperation extends ApplicationRecord {
  static jsonapiType = 'dotation_operations'

  @Attr() id!: string
  @Attr() name!: string
  @Attr() donorName!: string
  @Attr() donorLogo!: string
  @Attr() identifier!: string
  @Attr() bannerImage!: string
  @Attr() maximumAmount!: number
  @Attr() giftBoxColor!: string
  @Attr() giftRibbonColor!: string
  @Attr() remainingAmount!: number
  @Attr() dotationDuration!: string
  @Attr() cseKind!: string
  @Attr() startAt!: string
  @Attr() endAt!: string
  @Attr() createdAt!: string

  @BelongsTo() multiChoiceGiftCard!: MultiChoiceGiftCard
}
