import Vue from 'vue'
import Vuetify from 'vuetify'
import { colors } from 'vuetify/lib'

// Style vuetify
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

let primaryColor = '#f66378'
let secondaryColor = '#6e69ce'

const localStorageSettings = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '') : undefined
if (localStorageSettings?.primaryColor && localStorageSettings?.secondaryColor) {
  primaryColor = localStorageSettings.primaryColor
  secondaryColor = localStorageSettings.secondaryColor
}

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: primaryColor,
        secondary: secondaryColor,
        app: '#EEEFF5',
        background: '#2b2b2a',
        // TEXT VAR
        headlineText: '#2C1936',
        primaryText: '#4F3A5B',
        // COLORS
        giftOrder: '#D1B000',
        green: '#4ac468',
        yellow: '#fbc02d',
        orange: '#f28d4f',
        danger: '#ed6e97',
        blue: '#3FA5FF',
      },
      dark: {
        primary: colors.blue.lighten3,
        app: '#303030'
      }
    }
  }
})
