/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'info_rect': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4 0a4 4 0 0 0-4 4v26a4 4 0 0 0 4 4h26a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4zm14.292 11.042h-2.417v2.416h2.417v-2.416zm0 10.875c0 .664-.544 1.208-1.209 1.208a1.212 1.212 0 0 1-1.208-1.208v-4.834c0-.664.544-1.208 1.208-1.208.665 0 1.209.544 1.209 1.208v4.834zM5 17.083C5 10.413 10.413 5 17.083 5s12.084 5.413 12.084 12.083-5.414 12.084-12.084 12.084S5 23.753 5 17.083zm2.417 0c0 5.33 4.338 9.667 9.666 9.667 5.33 0 9.667-4.338 9.667-9.667 0-5.328-4.338-9.666-9.667-9.666-5.328 0-9.666 4.338-9.666 9.666z" _fill="#00C0B5"/>'
  }
})
