
import { Vue, Component } from 'vue-property-decorator'
import { useUserStore } from '@/stores/user'
// import { VueIdentifyNetwork } from 'vue-identify-network'

@Component({
  name: 'SystemBar',
  components: {
    // VueIdentifyNetwork
  }
})
export default class extends Vue {
  private userStore = useUserStore()
  private countDown = -1
  private hours = 0
  private minutes = 0
  private seconds = 0

  private show = false
  private showInternet = false
  private networkArray: string[] = []

  created() {
    this.initCountDown()
  }

  get isLyfPayUser() {
    return this.userStore.isLyfPayCustomer
  }

  get tokenExpiresIn() {
    return this.userStore.tokenExpiresIn
  }

  private handleNetworkIdentified(network: string): void {
    if (network.length > 0) {
      this.showInternet = true
      setTimeout(() => {
        this.showInternet = false
      }, 2500)
    }

    this.networkArray.push(network)
  }

  private async initCountDown() {
    if (this.tokenExpiresIn !== -1) {
      this.countDown = this.tokenExpiresIn
      const countDownDate = this.$moment().add(this.tokenExpiresIn, 'seconds')

      setInterval(() => {
        const distance = this.$moment.duration(countDownDate.diff(this.$moment()))

        this.hours = distance.hours()
        this.minutes = distance.minutes()
        this.seconds = distance.seconds()
        this.countDown -= 1

        if (this.countDown <= 600 && !this.show) this.show = true
      }, 1000)
    }
  }
}
