/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'log_out': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.166 1.45a.717.717 0 0 0-.716.716v8.167a.717.717 0 0 0 .716.717H4.5a.45.45 0 0 1 0 .9H2.166A1.617 1.617 0 0 1 .55 10.333V2.166A1.617 1.617 0 0 1 2.166.55H4.5a.45.45 0 1 1 0 .9H2.166zM8.265 3.015a.45.45 0 0 1 .636 0l2.917 2.916a.45.45 0 0 1 0 .637L8.9 9.484a.45.45 0 1 1-.636-.636l2.598-2.599-2.598-2.598a.45.45 0 0 1 0-.636z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M4.05 6.25a.45.45 0 0 1 .45-.45h7a.45.45 0 0 1 0 .9h-7a.45.45 0 0 1-.45-.45z" _fill="#000"/>'
  }
})
