/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'notif': {
    width: 27,
    height: 27,
    viewBox: '0 0 27 27',
    data: '<path pid="0" d="M13.926.41c.95 0 1.721.77 1.721 1.721v.895a8.59 8.59 0 0 1 6.918 8.425v8.04h1.557a1.13 1.13 0 0 1 0 2.26H3.835a1.13 1.13 0 0 1 0-2.26H5.39v-8.04a8.59 8.59 0 0 1 6.814-8.404v-.916c0-.95.77-1.72 1.721-1.72zm.052 4.554a6.534 6.534 0 0 0-6.533 6.533v7.993h13.067v-7.993a6.534 6.534 0 0 0-6.09-6.519l-.232-.011zm3.234 18.032a3.292 3.292 0 0 1-6.467 0z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
