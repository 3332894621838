/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'wallet_rect': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4 0a4 4 0 0 0-4 4v26a4 4 0 0 0 4 4h26a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4zm23.42 9a3 3 0 0 0-3-3H9.582c-.119 0-.236.007-.352.02-1.367.128-2.463 1.188-2.615 2.532A3.024 3.024 0 0 0 6.583 9v1.168h-.01v5.91a2.49 2.49 0 0 0 0 4.63V23.5l.006.193c.102 1.855 1.656 3.323 3.554 3.323h15.945l.195-.005c1.872-.1 3.364-1.631 3.364-3.51V13.75l-.006-.193a3.52 3.52 0 0 0-2.211-3.064V9zM8.081 15.904l.002-4.537c.422.234.908.367 1.426.367h16.568l.154.006c1.067.077 1.905.95 1.905 2.01v9.75l-.006.15c-.078 1.041-.965 1.866-2.053 1.866H10.133l-.154-.005c-1.068-.078-1.905-.95-1.905-2.01l-.001-.587.01.007-.001-2.04h2.02a2.489 2.489 0 0 0 2.488-2.488l-.006-.163a2.489 2.489 0 0 0-2.483-2.326H8.082zm-.593 1.5H10.1c.546 0 .989.443.989.99l-.007.114a.989.989 0 0 1-.982.874H8.082l-.61-.002.017.002a.989.989 0 1 1 0-1.978zM23.253 7.5l1.166.001.145.007A1.5 1.5 0 0 1 25.919 9v1.168h.02l.256.07-.117-.004H9.51l-.137-.006C8.655 10.16 8.1 9.576 8.1 8.87c0-.04.002-.078.004-.117a1.5 1.5 0 0 1 1.298-1.243l.109-.004h13.743V7.5z" _fill="#00C0B5"/>'
  }
})
