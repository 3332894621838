/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'shop_rect': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 4a4 4 0 0 1 4-4h26a4 4 0 0 1 4 4v26a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4zm29.854 9.066l-4.85-6.938a.808.808 0 0 0-.662-.345H9.927a.807.807 0 0 0-.65.329l-5.12 6.937a.81.81 0 0 0-.157.48c0 1.6 1.167 2.93 2.694 3.192v8.981a2.517 2.517 0 0 0 2.515 2.515h15.784a2.517 2.517 0 0 0 2.514-2.515v-9.036a3.246 3.246 0 0 0 2.42-2.8.808.808 0 0 0-.073-.8zM23.13 26.6h-3.368v-6.904h3.368V26.6zm2.76-.898a.898.898 0 0 1-.897.898h-.247v-7.712a.808.808 0 0 0-.808-.808h-4.984a.808.808 0 0 0-.809.808V26.6H9.21a.899.899 0 0 1-.898-.898v-9.115c.53-.186.999-.505 1.363-.918a3.234 3.234 0 0 0 4.865 0 3.234 3.234 0 0 0 4.866 0 3.234 3.234 0 0 0 4.865 0c.422.479.984.829 1.62.994v9.04zm.813-10.548a1.626 1.626 0 0 1-1.624-1.625.808.808 0 1 0-1.617 0c0 .896-.729 1.625-1.624 1.625a1.626 1.626 0 0 1-1.625-1.625.808.808 0 0 0-1.616 0c0 .896-.73 1.625-1.625 1.625a1.626 1.626 0 0 1-1.625-1.625.808.808 0 1 0-1.616 0c0 .896-.729 1.625-1.625 1.625a1.626 1.626 0 0 1-1.624-1.625.808.808 0 1 0-1.617 0c0 .896-.728 1.625-1.624 1.625-.814 0-1.49-.601-1.607-1.383L10.335 7.4h13.586l4.399 6.292a1.627 1.627 0 0 1-1.617 1.462zM10.4 18.08h5.513c.446 0 .808.362.808.808v5.052a.808.808 0 0 1-.808.808h-5.513a.808.808 0 0 1-.808-.808v-5.052c0-.446.362-.808.808-.808zm.808 5.052h3.897v-3.436h-3.897v3.436z" _fill="#00C0B5"/>'
  }
})
