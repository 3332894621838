/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'api_enabled': {
    width: 273,
    height: 273,
    viewBox: '0 0 273 273',
    data: '<path pid="0" d="M219.852 111.494a17.073 17.073 0 0 0-15.104-9.119h-32.109l38.84-77.681a17.075 17.075 0 0 0-.749-16.6A17.055 17.055 0 0 0 196.217 0h-85.313a17.06 17.06 0 0 0-16.188 11.668L52.06 139.637a17.065 17.065 0 0 0 16.188 22.457h39.832l-14.038 91.248c-1.209 7.865 3.174 15.526 10.572 18.458a17.081 17.081 0 0 0 20.353-6.194l93.844-136.5a17.064 17.064 0 0 0 1.041-17.612z" _fill="#FFEA29"/>'
  }
})
