import { Model, SpraypaintBase, MiddlewareStack } from 'spraypaint'
import router from '@/router/index'
import { useUserStore } from '@/stores/user'
import NotificationService from '@/services/NotificationService'

@Model()
class ApplicationRecord extends SpraypaintBase {
  static baseUrl: string = process.env.VUE_APP_API_URL || 'http://localhost:80'
  static apiNamespace = '/wallet/v1'
  static jwtStorage = false as const

  static generateAuthHeader(token: string): string {
    return `Bearer ${token}`
  }
}

const middleware: any = new MiddlewareStack()

middleware.afterFilters.push(async(response: any, json: any, requestOptions: any) => {
  const userStore = useUserStore()

  // Catch Also 403 ? (Bearer token not found)
  if (response.status === 401) {
    if (userStore.isLyfPayCustomer) {
      userStore.resetToken()

      router.push('/opt-in-refused')
    } else {
      try {
        await userStore.logOut(true)
        NotificationService.error('Session expirée', 'Merci de vous reconnecter')
      } catch (err) {
        if (router.currentRoute.path !== '/login') {
          router.push('/login')

          NotificationService.error('Session expirée', 'Merci de vous reconnecter')
        }

        throw ('abort')
      }
    }
  } else if (response.status >= 400) {
    response.errors = json.errors || []

    throw ('abort')
  }
})

ApplicationRecord.middlewareStack = middleware

export default ApplicationRecord
