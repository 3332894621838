/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'password': {
    width: 26,
    height: 34,
    viewBox: '0 0 26 34',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.02 3.895C7.4 1.975 9.692.5 13.166.5s5.766 1.476 7.148 3.395c1.336 1.855 1.769 4.044 1.769 5.522v6.416h1.433a2.4 2.4 0 0 1 2.4 2.4v13.034a2.4 2.4 0 0 1-2.4 2.4h-20.7a2.4 2.4 0 0 1-2.4-2.4V18.233a2.4 2.4 0 0 1 2.4-2.4H4.25V9.417c0-1.478.433-3.667 1.77-5.522zm.73 11.938h12.834V9.417c0-1.078-.334-2.722-1.298-4.061C17.368 4.08 15.826 3 13.167 3c-2.66 0-4.2 1.08-5.119 2.356-.964 1.339-1.298 2.983-1.298 4.06v6.417zm-3.833 2.5v12.834h20.5V18.333h-20.5z" _fill="#000"/>'
  }
})
