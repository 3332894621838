/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'user': {
    width: 22,
    height: 24,
    viewBox: '0 0 22 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.953 16.819C3.034 15.809 4.49 15.25 6 15.25h10c1.509 0 2.966.559 4.047 1.569 1.084 1.01 1.703 2.394 1.703 3.848V23a.75.75 0 0 1-1.5 0v-2.333c0-1.022-.434-2.013-1.226-2.752-.794-.74-1.88-1.165-3.024-1.165H6c-1.143 0-2.23.424-3.024 1.165-.792.74-1.226 1.73-1.226 2.752V23a.75.75 0 0 1-1.5 0v-2.333c0-1.454.62-2.837 1.703-3.849zM6.934 1.934a5.75 5.75 0 1 1 8.132 8.132 5.75 5.75 0 0 1-8.132-8.132zM11 1.75a4.25 4.25 0 1 0 0 8.5 4.25 4.25 0 0 0 0-8.5z" _fill="#000"/>'
  }
})
