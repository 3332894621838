
import { Component, Vue } from 'vue-property-decorator'
import {useUserStore} from '@/stores/user'

import VueQr from 'vue-qr'

@Component({
  name: 'BottomNavigation',
  components: {
    VueQr
  }
})
export default class extends Vue {
  private userStore = useUserStore()

  private QRUrl = window.location.href

  get user(): any {
    return this.userStore.model
  }

  get isBemoveProgram(): any {
    return this.userStore.isBemoveCustomer
  }

  get appName(): string {
    return this.isBemoveProgram ? 'Bemove Avantages' : 'Walleo'
  }

  get appLogo(): string {
    return this.showWalleoLogo ? this.isBemoveProgram ? require('@/assets/logo/bemove-icon.svg') : require('@/assets/logo/logo-walleo-lite.svg') : ''
  }

  get showWalleoLogo(): boolean {
    return this.userStore.model.program.walleoLogoEnabled
  }
}
