/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'tutorial': {
    width: 34,
    height: 44,
    viewBox: '0 0 34 44',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.25 26.917c0-.69.56-1.25 1.25-1.25h15.333a1.25 1.25 0 1 1 0 2.5H9.5c-.69 0-1.25-.56-1.25-1.25zM8.25 19.25c0-.69.56-1.25 1.25-1.25h3.833a1.25 1.25 0 1 1 0 2.5H9.5c-.69 0-1.25-.56-1.25-1.25zM8.25 34.584c0-.69.56-1.25 1.25-1.25h7.667a1.25 1.25 0 0 1 0 2.5H9.5c-.69 0-1.25-.56-1.25-1.25z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M13.334.75c.69 0 1.25.56 1.25 1.25v2.583h14.083a5.083 5.083 0 0 1 5.084 5.084v28.75a5.083 5.083 0 0 1-5.084 5.083h-23a5.083 5.083 0 0 1-5.083-5.083V9.667a5.083 5.083 0 0 1 5.083-5.084h6.417V2c0-.69.56-1.25 1.25-1.25zm-1.25 6.333H5.667a2.583 2.583 0 0 0-2.583 2.584v28.75A2.583 2.583 0 0 0 5.667 41h23a2.584 2.584 0 0 0 2.584-2.583V9.667a2.583 2.583 0 0 0-2.584-2.584H14.584v2.584a1.25 1.25 0 0 1-2.5 0V7.083z" _fill="#000"/>'
  }
})
