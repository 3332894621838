import { Model, Attr } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'

@Model()
export default class PurchasedGiftCard extends ApplicationRecord {
  static jsonapiType = 'purchased_gift_cards'

  @Attr() name!: string
  @Attr() logoSquare!: string
  @Attr() createdAt!: Date
  @Attr() updatedAt!: Date
  @Attr() baseline!: string
  @Attr() description!: string | undefined
  @Attr() howToUseOnline!: string
  @Attr() howToUseOffline!: string
  @Attr() termOfUse!: string
  @Attr() websiteUrl!: string
  @Attr() barcodeSymbology!: number
  @Attr() validityPeriod!: number
  @Attr() checkBalanceUrl!: string
  @Attr() onlineUsable!: boolean
  @Attr() offlineUsable!: boolean
  @Attr() domUsable!: boolean
  @Attr() btocAvailable!: boolean
  @Attr() splittableOnline!: boolean
  @Attr() splittableOffline!: boolean
  @Attr() cumulativeOnline!: boolean
  @Attr() cumulativeOnlineLimit!: number
  @Attr() cumulativeOffline!: boolean
  @Attr() cumulativeOfflineLimit!: number
  @Attr() cumulativeWithVoucher!: boolean
  @Attr() codePin!: boolean
  @Attr() barcodeByConcatenation!: boolean
  @Attr() logo!: string
  @Attr() logoPrimaryColor!: string
  @Attr() apiEnabled!: boolean
  @Attr() passkitEnabled!: boolean
  @Attr() googleWalletEnabled!: boolean

  @Attr() cardsCount!: number
  @Attr() cardsAmountTotal!: number
  @Attr() cardsRemainingAmountTotal!: number
  @Attr() preOrderedCardsAmountTotal!: number
}
