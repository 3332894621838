export default {
  labels: {
    'input.message': 'Tapez votre message...',
    'conversations.title': 'Conversations',
    'start.new': 'Commencer une nouvelle conversation',
    'empty.messages': 'Aucun message pour le moment !',
    'no.more.messages': 'Plus de message !',
    'empty.conversations': 'Aucune conversation pour le moment !',
    'no.more.conversations': 'Plus de conversation !',
    'search.placeholder': 'Rechercher...',
    'location.placeholder': 'Entrez un lieu',
    'members.title': 'Membres',
    'typing': 'à taper..',
    'is.typing': 'est en train d\'écrire...',
    'online': 'En ligne',
    'offline': 'Hors ligne',
    'clear.messages': 'Effacer les messages',
    'delete': 'Surppimer',
    'reply': 'Répondre',
    'location.share.title': 'Partage de position',
    'my.location': 'Ma position',
    'send': 'Envoyer',
    'send.message': 'Envoyer le message',
    'smiley': 'Sélecteur d\'émoticônes',
    'close': 'Fermer',
    'edit': 'Editer',
    'save': 'Sauvegarder',
    'file.attachment': 'Télécharger la pièce jointe',
    'file.attach.title': 'Pièce jointe',
    'last.seen': 'Vu pour la dernière fois',
    'last.seen.on': 'Vu pour la dernière fois le',
    'time.format.AM': 'AM',
    'time.format.PM': 'PM',
    'hour': ' heure',
    'min': ' min',
    'yesterday': 'hier',
    'hours': ' heures',
    'mins': ' mins',
    'ago': '',
    'admin': 'Admin',
    'user': 'Utilisateur',
    'member': 'Membre',
    'you': 'Vous',
    'away': 'Indisponible',
    'closed.conversation.message': 'Cette conversation a été marquée comme résolue. Si vous avez d\'autres questions, envoyez simplement un message ici ou démarrez une nouvelle conversation.',
    'search.faq': 'Rechercher dans la FAQ...',
    'looking.for.something.else': 'Vous cherchez autre chose ?',
    'no-faq-found': 'Nous sommes ici pour aider.',
    'talk.to.agent': 'Parlez à un agent',
    'how.to.reachout': 'Comment pouvons-nous vous joindre?',
    'email.error.alert': 'Il semble que vous ayez entré un email invalide',
    'conversation.rated': 'noté la conversation',
    'char.limit.warn': 'Gardez votre message dans les 256 caractères pour aider le bot à comprendre facilement',
    'limit.remove': 'Retirer',
    'limit.characters': 'caractères',
    'limit.remaining': 'restant',
    'lead.collection': {
      'email': 'Email',
      'name': 'Nom',
      'phone': 'Numéro de téléphone',
      'title': 'Conversations',
      'heading': 'Avant de commencer, nous avons juste besoin de quelques détails afin de mieux vous servir',
      'submit': 'Démarrer la conversation',
    },
    'csat.rating': {
      'CONVERSATION_RATED': 'Vous avez évalué la conversation',
      'RATE_CONVERSATION': 'Évaluez votre conversation',
      'CONVERSATION_REVIEW_PLACEHOLDER': 'Ajouter un commentaire…',
      'OTHER_QUERIES': 'Vous avez d\'autres questions ? ',
      'RESTART_CONVERSATION': 'Redémarrer cette conversation',
      'SUBMIT_RATING' : 'Soumettez votre évaluation'
    },
    'conversation.header.dropdown': {
      'CSAT_RATING_TEXT': 'Évaluez cette conversation',
      'USER_OVERIDE_VOICE_OUTPUT_ON': 'Activer la synthèse vocale',
      'USER_OVERIDE_VOICE_OUTPUT_OFF': 'Désactiver la synthèse vocale',
    },
    'page.title.on.new.message': 'Nouveau message de ',
    'emoji.hover.text': {
      'poor': 'Bof',
      'great': 'Super',
      'average': 'Moyen'
    },
    'rich.message': {
      'notification.preview' : 'Message'
    },
    'filter.conversation.list': {
      'ALL_CONVERSATIONS': 'Toutes les conversations',
      'ACTIVE_CONVERSATIONS': 'Conversations actives',
      'HIDE_RESOLVED': 'Masquer résolu',
      'SHOW_RESOLVED': 'Afficher résolu'
    },
    'time.stamp': {
      'sec.ago': 'sec ago',
      'secs.ago': 'secs ago',
      'min.ago': 'min ago',
      'mins.ago': 'mins ago',
      'hr.ago': 'hr ago',
      'hrs.ago': 'hrs ago'
    },
    'voice.output': {
      'location': {
        'init': 'Un lieu a été partagé avec vous.',
        'lat': 'La latitude est ',
        'lon': 'et la longitude est '

      },
      'attachment': 'Vous avez une pièce jointe.'
    }
  }
}
