/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'wallet': {
    width: 25,
    height: 22,
    viewBox: '0 0 25 22',
    data: '<path pid="0" d="M19.427.505a3 3 0 0 1 3 3v1.493a3.52 3.52 0 0 1 2.212 3.064l.005.193v9.75c0 1.88-1.491 3.411-3.364 3.511l-.195.005H5.14c-1.898 0-3.452-1.468-3.554-3.322l-.005-.193v-2.793a2.49 2.49 0 0 1 0-4.63v-5.91h.009V3.505c0-.152.012-.301.033-.448C1.775 1.713 2.871.653 4.238.525c.116-.013.233-.02.352-.02h14.837zM3.09 5.873l-.002 4.536h2.02c1.319 0 2.398 1.028 2.482 2.326l.006.163a2.489 2.489 0 0 1-2.489 2.489H3.09v2.04l-.009-.008v.587c0 1.06.838 1.933 1.906 2.01l.154.005h15.945c1.088 0 1.975-.824 2.053-1.865l.006-.15v-9.75c0-1.06-.838-1.934-1.905-2.011l-.154-.005H4.517a2.935 2.935 0 0 1-1.426-.367zm2.017 6.037H2.496a.989.989 0 1 0 0 1.977l-.017-.002.61.001 2.02.001a.989.989 0 0 0 .981-.873l.007-.116a.989.989 0 0 0-.989-.988zm14.319-9.905H18.26v.007H4.518l-.109.004c-.661.08-1.19.591-1.298 1.244a2.002 2.002 0 0 0-.004.116c0 .705.556 1.29 1.274 1.357l.137.007h16.568l.117.002-.256-.069h-.02V3.505a1.5 1.5 0 0 0-1.355-1.493l-.144-.007z" _fill="#000" fill-rule="nonzero"/>'
  }
})
