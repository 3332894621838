import { Model, Attr } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'

@Model()
export default class CustomerInvoice extends ApplicationRecord {
  static jsonapiType = 'customer_invoices'

  @Attr() createdAt!: string
  @Attr() updatedAt!: string
  @Attr() amount!: number
  @Attr() status!: string
  @Attr() number!: string
  @Attr() file!: string
}
