/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'warning': {
    width: 95,
    height: 95,
    viewBox: '0 0 95 95',
    data: '<path pid="0" d="M47.5 23.71l29.806 51.498H17.694L47.5 23.71zM10.846 71.25c-3.048 5.264.752 11.875 6.848 11.875h59.612c6.096 0 9.896-6.61 6.848-11.875L54.348 19.752c-3.048-5.265-10.648-5.265-13.696 0L10.846 71.25zm32.696-27.708v7.916a3.97 3.97 0 0 0 3.958 3.959 3.97 3.97 0 0 0 3.958-3.959v-7.916a3.97 3.97 0 0 0-3.958-3.959 3.97 3.97 0 0 0-3.958 3.959zm0 19.791h7.916v7.917h-7.916v-7.917z" _fill="#F79E1B"/>'
  }
})
