import Vue from 'vue'
import router from './router'
import App from './App.vue'
import Router from 'vue-router'
import i18n from "@/i18n"

// Utils
import '@/permission'
import '@/utils/class-component-hooks'

// Service Worker (PWA)
import '@/pwa/register-service-worker'

// SVG icons
import '@/icons/components'

// Packages
import vuetify from './plugins/vuetify'
import VueFlicking from '@egjs/vue-flicking'
import VueClipboard from 'vue-clipboard2'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import VueSweetalert2 from 'vue-sweetalert2'
import vueDebounce from 'vue-debounce'
import VueSplash from 'vue-splash'
import VueSkeletonLoading from 'vue-skeleton-loading'
import { longClickDirective } from 'vue-long-click'
import SvgIcon from 'vue-svgicon'
import Donut from 'vue-css-donut-chart'
import VueMoment from 'vue-moment'
import device from 'vue-device-detector-js'
import LottieVuePlayer from '@lottiefiles/vue-lottie-player'
import moment from 'moment'
import VueTelInputVuetify from 'vue-tel-input-vuetify'
require('moment/locale/fr')

// Configs
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    release: `walleo@` + process.env.npm_package_version,
    dsn: 'https://5f99949e7abb43c6bb631ab47f449cef@o137618.ingest.sentry.io/5828430',
    tracesSampleRate: 0.8,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["api.staging.walleo.io", "api.walleo.io"],
      })
    ],
    trackComponents: true,
    environment: process.env.VUE_APP_ENV,
    attachProps: true,
    beforeSend(event) {
      const ignore_patterns = [/Request failed with status/i, /this\.src is null/i]
      if (event && event.message) {
        const ignored = ignore_patterns.find(pat => event?.message?.match(pat))
        return ignored ? null : event
      } else {
        return event
      }
    }
  })
}

// Import
Vue.use(VueFlicking)
Vue.use(VueMoment, { moment })
Vue.use(VueClipboard)
Vue.use(VueSweetalert2)
Vue.use(VueSplash)
Vue.use(VueSkeletonLoading)
Vue.config.productionTip = false
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
})
Vue.use(device)
Vue.use(Donut)
Vue.use(vueDebounce)
Vue.use(LottieVuePlayer)
Vue.use(VueTelInputVuetify, {
  vuetify
})


// Styles
import './stylesheet/font.sass'
import './stylesheet/Styles.sass'
import './stylesheet/Global.sass'
import './stylesheet/MaterialIcons.scss'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-swipe-actions/dist/vue-swipe-actions.css'
import 'vue-css-donut-chart/dist/vcdonut.css'

// Directives
const longClickInstance = longClickDirective({delay: 3200, interval: 3200})
Vue.directive('longclick', longClickInstance)

const longIncrement = longClickDirective({delay: 200, interval: 30})
Vue.directive('incr', longIncrement)

// Pinia
import { createPinia, PiniaVuePlugin } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

Vue.use(Router) // Router plugin MUST be initialized AFTER Pinia (so pinia can be instancied)

new Vue({
  router,
  i18n,
  pinia,
  vuetify,
  render: h => h(App)
}).$mount('#app')
