import { Model, Attr, HasMany } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { CardStock, Category, GiftCardDiscount, Sku } from '@/models/index'

@Model()
export default class GiftCard extends ApplicationRecord {
  static jsonapiType = 'gift_cards'

  @Attr() name!: string
  @Attr() createdAt!: Date
  @Attr() updatedAt!: Date
  @Attr() baseline!: string
  @Attr() description!: string | undefined
  @Attr() howToUseOnline!: string
  @Attr() howToUseOffline!: string
  @Attr() termOfUse!: string
  @Attr() websiteUrl!: string
  @Attr() barcodeSymbology!: number
  @Attr() validityPeriod!: number
  @Attr() checkBalanceUrl!: string
  @Attr() onlineUsable!: boolean
  @Attr() offlineUsable!: boolean
  @Attr() domUsable!: boolean
  @Attr() btocAvailable!: boolean
  @Attr() splittableOnline!: boolean
  @Attr() splittableOffline!: boolean
  @Attr() cumulativeOnline!: boolean
  @Attr() cumulativeOnlineLimit!: number
  @Attr() cumulativeOffline!: boolean
  @Attr() cumulativeOfflineLimit!: number
  @Attr() cumulativeWithVoucher!: boolean
  @Attr() codePin!: boolean
  @Attr() barcodeByConcatenation!: boolean
  @Attr() logo!: string
  @Attr() logoSquare!: string
  @Attr() logoPrimaryColor!: string
  @Attr() apiEnabled!: boolean
  @Attr() passkitEnabled!: boolean
  @Attr() googleWalletEnabled!: boolean
  @Attr() centsEnabled!: boolean

  @HasMany() discounts!: GiftCardDiscount[]
  @HasMany() skus!: Sku[]
  @HasMany() cardStocks!: CardStock[]
  @HasMany() categories!: Category[]

  get discount() {
    return this.discounts[0]
  }

  get isFaceValueFree() {
    if (this.skus === undefined || this.skus.length === 0) return false

    return this.skus.map(s => s.faceValueFree).includes(true)
  }

  get minAmountFaceValueFree() {
    const skuFree = this.skus.find((sku: Sku) => sku.faceValueFree)

    if (!skuFree) return 0
    else return skuFree.faceValueMin
  }
}
