/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'discount': {
    width: 710,
    height: 710,
    viewBox: '0 0 710 710',
    data: '<path pid="0" d="M576.432 101.026L100.855 575.27c-10.755 10.725-10.78 28.137-.055 38.891 10.724 10.755 28.136 10.779 38.891.055l475.577-474.245c10.755-10.724 10.779-28.136.055-38.891-10.725-10.754-28.137-10.779-38.891-.054z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M133.076 266.422c73.454.103 133.083-59.36 133.186-132.814C266.365 60.155 206.903.525 133.449.422 59.995.319.366 59.782.263 133.235c-.103 73.454 59.36 133.084 132.813 133.187zM133 223.126c49.706.07 90.057-40.168 90.126-89.874.07-49.705-40.168-90.056-89.873-90.126-49.706-.07-90.057 40.168-90.126 89.874-.07 49.705 40.168 90.056 89.873 90.126zM576.517 709.108c73.454.103 133.084-59.36 133.187-132.814.103-73.453-59.36-133.083-132.814-133.186-73.453-.103-133.083 59.359-133.186 132.813-.103 73.454 59.359 133.084 132.813 133.187zm.484-42.982c49.705.07 90.056-40.168 90.126-89.874.07-49.705-40.168-90.056-89.874-90.126-49.705-.069-90.056 40.168-90.126 89.874-.07 49.705 40.168 90.056 89.874 90.126z" _fill="#000"/>'
  }
})
