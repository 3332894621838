import { defineStore } from 'pinia'

interface State {
  PWAPrompt: boolean
  showPWAInstallCard: boolean
  relatedAppUrl: string
  showUnavailableCards: boolean
  showChartWallet: boolean
  showLegendChartWallet: boolean
}

export const useSettingsStore = defineStore('settings', {
  state: (): State => ({
    PWAPrompt: false,
    showPWAInstallCard: false,
    relatedAppUrl: '',
    showUnavailableCards: false,
    showChartWallet: true,
    showLegendChartWallet: true,
  }),
  getters: {
  },
  actions: {
    clearSettings () {
      this.$reset()
    }
  },
  persist: true
})
