
import { Component, Vue } from 'vue-property-decorator'
import NotificationService from '@/services/NotificationService'

@Component({
  name: 'ServiceWorkerUpdatePopup'
})
export default class extends Vue {
  private refreshing = false
  private registration: ServiceWorkerRegistration | null = null

  created() {
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  }

  render() {
    // Avoid warning for missing template
  }

  private async showRefreshUI(e: Event) {
    // Display a notification inviting the user to refresh/reload the app due
    // to an app update being available.
    // The new service worker is installed, but not yet active.
    // Store the ServiceWorkerRegistration instance for later use.

    this.registration = (e as CustomEvent).detail

    const refresh = await NotificationService.pwaUpdate()
    if (refresh) {
      e.preventDefault()
      this.refreshApp()
    }
  }

  private refreshApp() {
    // Protect against missing registration.waiting.
    if (!this.registration || !this.registration.waiting) return
    this.registration.waiting.postMessage('skipWaiting')
  }
}
