import request from '@/utils/request'

export const login = (data: any) =>
  request({
    url: '/wallet/oauth/token',
    method: 'post',
    data
  })

export const signInWithAuthorizationCode = (data: any) =>
  request({
    url: '/oauth/token',
    method: 'post',
    data
  })

export const loginWithLyfpay = (data: any) =>
  request({
    url: '/wallet/lyfpay/token',
    method: 'post',
    data
  })

export const loginWithPcs = (data: any) =>
  request({
    url: '/wallet/pcs/token',
    method: 'post',
    data
  })

export const logout = (data: any) =>
  request({
    url: '/oauth/revoke',
    method: 'post',
    data
  })

export const fetchBalance = (externalToken: string) =>
  request({
    url: `/wallet/v1/user/balance?jwt=${externalToken}`,
    method: 'get'
  })

export const resetPassword = (data: any) =>
  request({
    url: '/wallet/users/password',
    method: 'put',
    data
  })

  export const sendMagicLink = (data: any) =>
  request({
    url: '/wallet/v1/magic_link/send_by_email',
    method: 'post',
    data
  })

export const checkUnconfirmedUser = (email: string) =>
  request({
    url: `/wallet/v1/unconfirmed_customers?filter[email]=${email}`,
    method: 'get'
  })

export const sendConfirmationEmail = (data: any) =>
  request({
    url: `/wallet/v1/unconfirmed_customers/${data.id}`,
    method: 'put',
    data
  })

export const confirmEmail = (confirmationToken: string) =>
  request({
    url: `/wallet/users/confirmation?confirmation_token=${confirmationToken}`,
    method: 'get'
  })
