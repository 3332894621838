
import { Component, Vue } from 'vue-property-decorator'
import {useUserStore} from '@/stores/user'

@Component({
  name: 'BottomNavigation',
  components: {
    NotificationButton: () => import('@/components/NotificationButton.vue')
  }
})
export default class extends Vue {
  private userStore = useUserStore()

  private value = 0
  private navs = [
    {
      text: 'Accueil',
      to: '/home',
    },
    {
      text: 'Achat eBons',
      to: '/search',
    },
    {
      text: 'Portefeuille',
      to: '/wallet/ebons',
    },
    {
      text: 'Profil',
      to: '/profile',
      icon: 'user'
    }
  ]

  get user(): any {
    return this.userStore.model
  }

  get isBemoveUser() {
    return this.userStore.isBemoveCustomer
  }

  get appLogo(): any {
    return this.isBemoveUser ? require('@/assets/logo/bemove-icon.svg') : require('@/assets/logo/logo-walleo-lite.svg')
  }

  get programName(): string {
    return this.userStore.model.program.name
  }

  get fallbackUrl(): any {
    return this.userStore.model.program.fallbackUrl
  }

  get showWalleoLogo(): any {
    return this.userStore.model.program.walleoLogoEnabled
  }

  created(): void {
    this.navs.forEach((nav, i) => {
      if (this.$route.path.includes(nav.to)) this.value = i
    })
  }

  private openUrl(url: string) {
    window.open(url, '_self')
  }
}
