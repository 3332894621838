import { Model, Attr } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'

@Model()
export default class Category extends ApplicationRecord {
  static jsonapiType = 'categories'

  @Attr() name!: string
  @Attr() logo!: string
}
