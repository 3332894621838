import axios from 'axios'
import NotificationService from '@/services/NotificationService'
import { useUserStore } from '@/stores/user'

const whiteList = [
  'opt_in_refused'
]

const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000
})

// Request interceptors
service.interceptors.request.use(
  (config) => {
    const userStore = useUserStore()
    // Add X-Access-Token header to every request, you can add other custom headers here

    if (userStore.accessToken) {
      if (config.headers) config.headers.Authorization = `Bearer ${userStore.accessToken}`
    }

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  (response) => {
    const res = response.data

    if (res.code < 200 || res.code >= 300) {
      NotificationService.error('Erreur', res.message || 'Error')

      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        location.reload()
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return response.data
    }
  },
  (error) => {
    const errors = error.response.data.errors
    if (error.response.status !== 400) {
      for (const e of errors) {
       if (!whiteList.includes(e.code)) NotificationService.error('Erreur', e.detail)
      }
    }
    return Promise.reject(error)
  }
)

export default service
