const tokenKey = 'jwt_access_token'
const externalTokenKey = 'external_token'

export const getToken = (): string | null => localStorage.getItem(tokenKey)
export const setToken = (token: string): void => localStorage.setItem(tokenKey, token)
export const removeToken = (): void => localStorage.removeItem(tokenKey)

export const getExternalToken = (): string | null => localStorage.getItem(externalTokenKey)
export const setExternalToken = (externalToken: string): void => localStorage.setItem(externalTokenKey, externalToken)
export const removeExternalToken = (): void => localStorage.removeItem(externalTokenKey)
