import {
  Model,
  Attr,
  BelongsTo
} from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { CustomerOrder, Sku } from '@/models/index'

@Model()
export default class CardDistribution extends ApplicationRecord {
  static jsonapiType = 'card_distributions'

  @Attr() quantity!: number
  @Attr({ persist: false }) amount!: number

  @BelongsTo() order!: CustomerOrder
  @BelongsTo() sku!: Sku
}
