/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'flame': {
    width: 16,
    height: 16,
    viewBox: '0 0 611.999 611.999',
    data: '<path pid="0" d="M216.02 611.195c5.978 3.178 12.284-3.704 8.624-9.4-19.866-30.919-38.678-82.947-8.706-149.952 49.982-111.737 80.396-169.609 80.396-169.609s16.177 67.536 60.029 127.585c42.205 57.793 65.306 130.478 28.064 191.029-3.495 5.683 2.668 12.388 8.607 9.349 46.1-23.582 97.806-70.885 103.64-165.017 2.151-28.764-1.075-69.034-17.206-119.851-20.741-64.406-46.239-94.459-60.992-107.365-4.413-3.861-11.276-.439-10.914 5.413 4.299 69.494-21.845 87.129-36.726 47.386-5.943-15.874-9.409-43.33-9.409-76.766 0-55.665-16.15-112.967-51.755-159.531-9.259-12.109-20.093-23.424-32.523-33.073-4.5-3.494-11.023.018-10.611 5.7 2.734 37.736.257 145.885-94.624 275.089-86.029 119.851-52.693 211.896-40.864 236.826 22.616 47.759 54.162 75.806 84.97 92.187z"/>'
  }
})
