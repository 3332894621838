/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'history': {
    width: 38,
    height: 38,
    viewBox: '0 0 38 38',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.154 10.334c.69 0 1.25.56 1.25 1.25v7.148l3.468 3.468a1.25 1.25 0 1 1-1.768 1.767l-3.834-3.833a1.25 1.25 0 0 1-.366-.884v-7.666c0-.69.56-1.25 1.25-1.25z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M29.681 7.03A16 16 0 0 0 3.244 17.464a1.25 1.25 0 1 1-2.487-.253 18.5 18.5 0 1 1 1.027 8.222 1.25 1.25 0 1 1 2.349-.858A16 16 0 1 0 29.68 7.03z" _fill="#000"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M.75 25.004c0-.69.56-1.25 1.25-1.25h9.583a1.25 1.25 0 0 1 0 2.5H3.25v8.333a1.25 1.25 0 1 1-2.5 0v-9.583z" _fill="#000"/>'
  }
})
