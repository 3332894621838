import { Model, Attr } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'

@Model()
export default class CreditCard extends ApplicationRecord {
  static jsonapiType = 'credit_cards'

  @Attr() token!: string
  @Attr() pan!: string
  @Attr() expiryMonth!: string
  @Attr() expiryYear!: string
  @Attr() brand!: string
  @Attr() issuerName!: string
  @Attr() holderName!: string
  @Attr() default!: boolean
}
