import { Model, Attr, HasMany, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Card, DotationOperation, MultiChoiceCard } from '@/models/index'

import request from '@/utils/request'

@Model()
export default class Gift extends ApplicationRecord {
  static jsonapiType = 'gifts'

  @Attr() id!: string
  @Attr() createdAt!: string
  @Attr() senderType!: string
  @Attr() message!: string

  @BelongsTo() sender!: any

  @HasMany() cards!: Card[]
  @HasMany() multiChoiceCards!: MultiChoiceCard[]

  public async open() {
    return await request.get(`/wallet/v1/gifts/${this.id}/open`)
  }

  public isMultiChoiceCard(): boolean {
    return this.cards.length === 0
  }

  public isSendByCaretaker(): boolean {
    return this.senderType === 'Caretaker'
  }

  public totalAmount(): number {
    if (this.cards.length > 0)
      return this.cards.reduce((acc: number, current: Card): number => {
        return acc + current.amount
      }, 0)
    else if (this.multiChoiceCards.length > 0)
      return this.multiChoiceCards.reduce((acc: number, current: MultiChoiceCard): number => {
        return acc + current.amount
      }, 0)
    else
      return 0
  }

  public giftCardNameOneCard(): string {
    return this.cards[0].giftCard.name
  }

  public dotationOperationInformation(): DotationOperation {
    return this.multiChoiceCards[0].dotationOperation
  }
}
