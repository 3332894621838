import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Gift, GiftCard, Sku } from '@/models/index'

import request from '@/utils/request'

@Model()
export default class Card extends ApplicationRecord {
  static jsonapiType = 'cards'

  @Attr() amount!: number
  @Attr() remainingAmount!: number
  @Attr() usageStatus!: string
  @Attr() pinCode!: string
  @Attr() code!: string
  @Attr() barcode!: string
  @Attr() passkitUrl!: string
  @Attr() googleWalletUrl!: string
  @Attr() externalPdfUrl!: string
  @Attr() expireAt!: string
  @Attr() updatedAt!: string

  @BelongsTo() giftCard!: GiftCard
  @BelongsTo() sku!: Sku
  @BelongsTo() gift!: Gift

  public getCardStatusColor(): string {
    if (this.usageStatus === 'unused') return 'green'
    else if (this.usageStatus === 'partially_used') return 'orange'
    else if (this.usageStatus === 'used') return 'red'
    else return 'grey'
  }

  public archive(): void {
    this.usageStatus = 'archived'
    this.save({ returnScope: Card.includes('gift') })
  }

  public isGift(): boolean {
    return !!this.gift
  }

  public unarchive(): void {
    this.usageStatus = 'used'
    this.save()
  }

  public async generatePDF() {
    return await request.get(`/wallet/v1/cards/${this.id}/generate_pdf`, { responseType: 'blob', timeout: 10000 })
  }

  public async generateGoogleWalletUrl() {
    return await request.get(`/wallet/v1/cards/${this.id}/generate_google_wallet_url`)
  }

  public async downloadGoogleWallet() {
    if (!this.googleWalletUrl) {
      const { data } = await this.generateGoogleWalletUrl()
      this.googleWalletUrl = data.googleWalletUrl
    }

    const url = this.googleWalletUrl
    const link = document.createElement('a')
    link.href = url
    document.body.appendChild(link)
    link.click()
  }

  public async generatePasskitUrl() {
    return await request.get(`/wallet/v1/cards/${this.id}/generate_passkit_url`)
  }

  public async downloadPasskit() {
    if (!this.passkitUrl) {
      const { data } = await this.generatePasskitUrl()
      this.passkitUrl = data.passkitUrl
    }

    const url = this.passkitUrl
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${this.id}.pkpass`)
    document.body.appendChild(link)
    link.click()
  }

  // TODO:
  // We want to be able to download the pkpass directly, without generating an url.
  // Because the url is accessible without authentication, we don't want that in this context.
  // Actually it doesn't work, feel free to try :)
  public async downloadPasskit2() {
    request.get(`/wallet/v1/cards/${this.id}/generate_passkit`, { responseType: 'blob' }).then((response: any) => {
      if (response) {
        const file = new Blob([response.data])
        const fileURL = URL.createObjectURL(file)
        const link = document.createElement('a')
        link.href = fileURL
        link.setAttribute('download', `${this.id}.pkpass`)
        document.body.appendChild(link)
        link.click()
      }
    })
  }
}
