import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Sku, GiftCard } from '@/models/index'

@Model()
export default class CardStock extends ApplicationRecord {
  static jsonapiType = 'card_stocks'

  @Attr() name!: string
  @Attr() inStockCount!: number

  @BelongsTo() manageable!: any
  @BelongsTo() giftCard!: GiftCard
  @BelongsTo() sku!: Sku
}
