/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'sort_name': {
    width: 16,
    height: 16,
    viewBox: '0 0 32 32',
    data: '<path pid="0" _fill="none" _stroke="#000" stroke-width="2" stroke-miterlimit="10" d="M23 26.1V5M18.7 21.8l4.3 4.3 4.3-4.3M12 15v-1L9.1 6h-.2L6 14v1M6 12h6M5 18h7v1l-6 6v1h7"/>'
  }
})
