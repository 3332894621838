import { Model, Attr, BelongsTo } from 'spraypaint'
import ApplicationRecord from '@/models/ApplicationRecord'
import Program from '@/models/Program'

@Model()
export default class IncompleteUser extends ApplicationRecord {
  static jsonapiType = 'incomplete_customers'
  static endpoint = '/incomplete_user'
  static singularResource = true

  @Attr() email!: string
  @Attr() firstname!: string
  @Attr() lastname!: string
  @Attr() password!: string
  @Attr() phoneNumber!: string
  @Attr() passwordConfirmation!: string
  @Attr({ persist: false }) confirmedAt!: Date

  @BelongsTo() program!: Program
}
