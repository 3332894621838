import { Model, Attr } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'

@Model()
export default class Program extends ApplicationRecord {
  static jsonapiType = 'programs'

  @Attr({ persist: false }) name!: string
  @Attr({ persist: false }) description!: string
  @Attr({ persist: false }) createdAt!: string
  @Attr({ persist: false }) logo!: string
  @Attr({ persist: false }) earnDiscountsOnlyAfterFirstOrder!: boolean
  @Attr({ persist: false }) splashScreenLogo!: string
  @Attr({ persist: false }) splashScreenBackground!: string
  @Attr({ persist: false }) splashScreenBackgroundColor!: string
  @Attr({ persist: false }) multiChoiceGiftCardBrandName!: string
  @Attr({ persist: false }) giftEnabled!: boolean
  @Attr({ persist: false }) fallbackUrl!: string
  @Attr({ persist: false }) primaryColor!: string
  @Attr({ persist: false }) secondaryColor!: string
  @Attr({ persist: false }) balanceEnabled!: boolean
  @Attr({ persist: false }) balanceName!: string
  @Attr({ persist: false }) maxOrderAmount!: number
  @Attr({ persist: false }) walleoLogoEnabled!: boolean
  @Attr({ persist: false }) multiChoiceCardEnabled!: boolean
}
