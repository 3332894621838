/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'help': {
    width: 496,
    height: 496,
    viewBox: '0 0 496 496',
    data: '<path pid="0" d="M247.625 0a247.625 247.625 0 1 0 0 495.25 247.625 247.625 0 0 0 0-495.25zm0 459.875A212.247 212.247 0 0 1 51.532 328.85a212.254 212.254 0 0 1 46.01-231.308 212.252 212.252 0 0 1 300.166 0 212.249 212.249 0 0 1-150.083 362.333z" _fill="#000"/><path pid="1" d="M247.625 406.813a26.538 26.538 0 0 0 24.512-16.379 26.537 26.537 0 0 0-5.752-28.913 26.52 26.52 0 0 0-18.76-7.771 26.529 26.529 0 0 0-18.76 45.292 26.531 26.531 0 0 0 18.76 7.771zM265.313 106.125h-26.532a79.404 79.404 0 0 0-79.593 79.594 8.844 8.844 0 0 0 8.843 8.844h17.688a8.845 8.845 0 0 0 8.844-8.844 44.218 44.218 0 0 1 44.218-44.219h26.532a44.218 44.218 0 0 1 31.267 75.486c-8.293 8.293-17.955 10.139-31.267 13.639l-20.11 6.467a21.999 21.999 0 0 0-15.265 20.943v51.496h35.375v-44.218a79.594 79.594 0 0 0 0-159.188z" _fill="#000"/>'
  }
})
