/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'book': {
    width: 34,
    height: 38,
    viewBox: '0 0 34 38',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.667 3.083a2.583 2.583 0 0 0-2.583 2.584V32.5a1.25 1.25 0 0 1-2.5 0V5.667A5.083 5.083 0 0 1 5.667.583h25.684a2.4 2.4 0 0 1 2.4 2.4V28.12a1.25 1.25 0 1 1-2.5 0V3.084H5.667z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M4.417 28.667c0-.69.56-1.25 1.25-1.25H32.5a1.25 1.25 0 1 1 0 2.5H5.667c-.69 0-1.25-.56-1.25-1.25zM4.417 36.334c0-.69.56-1.25 1.25-1.25H32.5a1.25 1.25 0 1 1 0 2.5H5.667c-.69 0-1.25-.56-1.25-1.25z" _fill="#000"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M5.667 29.917a2.584 2.584 0 0 0 0 5.167 1.25 1.25 0 1 1 0 2.5 5.083 5.083 0 0 1 0-10.167 1.25 1.25 0 1 1 0 2.5zM10.167 9.5c0-.69.56-1.25 1.25-1.25h11.5a1.25 1.25 0 0 1 0 2.5h-11.5c-.69 0-1.25-.56-1.25-1.25z" _fill="#000"/>'
  }
})
