/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'trash': {
    width: 38,
    height: 38,
    viewBox: '0 0 38 38',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.75 15.917c.69 0 1.25.56 1.25 1.25v17.917h24.333V17.167a1.25 1.25 0 0 1 2.5 0v18.017a2.4 2.4 0 0 1-2.4 2.4H6.9a2.4 2.4 0 0 1-2.4-2.4V17.167c0-.69.56-1.25 1.25-1.25z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M15.334 15.917c.69 0 1.25.56 1.25 1.25v11.5a1.25 1.25 0 0 1-2.5 0v-11.5c0-.69.56-1.25 1.25-1.25zM23 15.917c.69 0 1.25.56 1.25 1.25v11.5a1.25 1.25 0 0 1-2.5 0v-11.5c0-.69.56-1.25 1.25-1.25zM12.75 3.083V8.25h12.834V3.083H12.75zM28.084 8.25V2.983a2.4 2.4 0 0 0-2.4-2.4H12.65a2.4 2.4 0 0 0-2.4 2.4V8.25H1.917a1.25 1.25 0 0 0 0 2.5h34.5a1.25 1.25 0 0 0 0-2.5h-8.333z" _fill="#000"/>'
  }
})
