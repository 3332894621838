
import { Component, Vue } from 'vue-property-decorator'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'BottomNavigation'
})
export default class extends Vue {
  private userStore = useUserStore()

  value = 0
  navs = [
    {
      text: 'Accueil',
      to: '/home',
      icon: 'home'
    },
    {
      text: 'Achat eBons',
      to: '/search',
      icon: 'ebons'
    },
    {
      text: 'Portefeuille',
      to: '/wallet/ebons',
      icon: 'wallet'
    }
  ]

  get filteredNavs(): any[] {
    if (this.userStore.isBemoveCustomer || this.userStore.isAvantagesIEGCustomer)
      return this.navs.filter(nav => nav.to !== '/home')
    return this.navs
  }
}
