/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'home': {
    width: 21,
    height: 23,
    viewBox: '0 0 21 23',
    data: '<path pid="0" d="M12.673 1.24l6.337 4.677a4.599 4.599 0 0 1 1.867 3.7v10.535a2.369 2.369 0 0 1-2.369 2.37h-4.61c-.967 0-1.76-.747-1.833-1.695l-.005-.145.002-3.99a.79.79 0 0 0-.789-.79H9.635a.79.79 0 0 0-.783.691l-.006.1.005 3.987a1.84 1.84 0 0 1-1.837 1.841H2.369A2.369 2.369 0 0 1 0 20.152V9.634c0-1.47.702-2.85 1.889-3.716l6.431-4.69a3.679 3.679 0 0 1 4.353.012zm-.862 1.168A2.227 2.227 0 0 0 9.326 2.3l-.15.1-6.432 4.691a3.147 3.147 0 0 0-1.286 2.343l-.007.2v10.518c0 .471.355.859.811.912l.107.006h4.643a.388.388 0 0 0 .38-.31l.007-.078-.002-4.08.015-.19a2.241 2.241 0 0 1 2.07-1.956l.153-.005h1.639a2.24 2.24 0 0 1 2.234 2.088l.005.154v3.991l.002.05.01.053c.038.14.155.248.3.276l.074.007h4.61c.47 0 .858-.354.91-.81l.007-.108V9.617c0-.932-.413-1.813-1.122-2.409l-.156-.123-6.337-4.677z" _fill="#000" fill-rule="nonzero"/>'
  }
})
