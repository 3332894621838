var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('vue-splash',{staticStyle:{"z-index":"1100"},style:(_vm.program.splashScreenBackground ? `background: url(${_vm.program.splashScreenBackground})` : `background-color: ${_vm.bgColor}`),attrs:{"show":_vm.showSplashScreen,"logo":_vm.logoDisplayed,"color":"","fixed":true,"custom-text":_vm.programId && _vm.showWalleoLogo
      ? `
    <div class='program'>
        <img src='${_vm.walleoLogo}' crossorigin alt='Walleo' />
    </div>
  `
      : ''}})
}
var staticRenderFns = []

export { render, staticRenderFns }