import router from '@/router'
import { Route } from 'vue-router'
import { useUserStore } from '@/stores/user'

const whiteList: string[] = [
  '/login',
  '/sign-up',
  '/forgot-password',
  '/reset-password',
  '/send-magic-link',
  '/send-magic-link?invalid=true',
  '/unconfirmed-email',
  '/confirmed-email',
  '/opt-in-refused',
  '/token-expired',
  '/cgv'
]

router.beforeEach(async (to: Route, _: Route, next: any) => {
  const userStore = useUserStore()
  let nextRoute = to.path === '/login' ? undefined : to.query.redirect

  const authorizationCode = to.query.code
  const lyfToken = to.query.jwt?.toString()
  const signInWithLyf = lyfToken !== undefined && lyfToken !== userStore.externalToken
  const pcsToken = to.query.pcs_jwt?.toString()
  const signInWithPcs = pcsToken !== undefined && pcsToken !== userStore.externalToken

  const autoSignInRedirect = to.query.sign_in_redirect === 'true'

  let replace = false

  if (authorizationCode) {
    await userStore.signinRedirectCallback()

    nextRoute = (nextRoute || to.path).toString()
    replace = true
  } else if (signInWithLyf) {
    await userStore.loginWithLyfpay(lyfToken)
    if (userStore.optInRefused) nextRoute = '/opt-in-refused'
  } else if (signInWithPcs) {
    await userStore.loginWithPcs(pcsToken)
  } else if (autoSignInRedirect) {
    await userStore.signinRedirect()
  }

  await userStore.getUser()

  if (userStore.user || userStore.token) {
    if (to.path === '/login' || to.path === '/sign-up') nextRoute = '/'
    else if (!userStore.model.isPersisted) {
      try {
        await userStore.getModelUser()
      } catch (err) {
        userStore.resetToken()

        nextRoute = `/login?redirect=${encodeURIComponent(to.fullPath)}`
        if (to.query.lyf) nextRoute += `&lyf=true`
      }
    }
  } else {
    const toPath = (nextRoute || to.path).toString()
    if (whiteList.indexOf(toPath) === -1) {
      nextRoute = `/login?sign_in_redirect=true&redirect=${encodeURIComponent(to.fullPath)}`
      if (to.query.lyf) nextRoute += `&lyf=true`
    }
  }

  const verification_status = ['waiting', 'verification_failed']
  if (verification_status.includes(userStore.model.registrationStatus) && to.path !== '/verification') nextRoute = '/verification'
  else if (!verification_status.includes(userStore.model.registrationStatus) && to.path == '/verification') nextRoute = '/home'

  if (userStore.model.locked && to.path !== '/locked') nextRoute = '/locked'
  else if (!userStore.model.locked && to.path === '/locked') nextRoute = '/home'

  next({ path: nextRoute, replace: replace })
})
