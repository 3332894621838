import { Vue } from 'vue-property-decorator'
import { notification, pwaToast} from '@/services/template/template'
import { mobileLyfComponent } from '@/services/template/lyf'
import swal from 'sweetalert2'

export default {
  success(title: string, message?: string): void {
    notification.fire({ icon: 'success', title: title, text: message, background: 'var(--v-green-base)' })
  },

  error(title: string, message?: string): void {
    notification.fire({ icon: 'error', title: title, text: message, background: 'var(--v-danger-base)' })
  },

  pwaUpdate(): Promise<boolean> {
    return new Promise(resolve => {
      pwaToast.fire({}).then(confirm => {
        if (confirm.isConfirmed) resolve(true)
        resolve(false)
      })
    })
  },

  openMobile(): void {
    Vue.component('mobileLyf', mobileLyfComponent)

    new Vue({
      el: '#modal',
      beforeCreate:  () => {
        swal.fire({
          width: '50%',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          html: '<div id="modal"><mobile-lyf></mobile-lyf></div>',
          didOpen: (dialog) => {
            const btnClose = dialog.querySelector('.btn-close')

            btnClose?.addEventListener('click', () => {
              swal.close()
            })
          }
        })
      }
    })
  }
}
