import swal from 'sweetalert2'

export const notification = swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 4000,
  iconColor: '#fff',
  timerProgressBar: true,
  didOpen: (toast: any) => {
    toast.addEventListener('mouseenter', swal.stopTimer)
    toast.addEventListener('mouseleave', swal.resumeTimer)
  }
})

export const pwaToast = swal.mixin({
  html: `
  <h4>Mise à jour</h4>
  <div>Une nouvelle version de Walleo est disponible</div>
  `,
  customClass: {
    popup: 'custom-popup',
    actions: 'justify-content-end',
    confirmButton: 'btn-resfresh-pwa'
  },
  toast: true,
  position: 'bottom',
  showConfirmButton: true,
  confirmButtonText: '<span class="material-icons">refresh</span>',
  showLoaderOnConfirm: true,
  showCloseButton: false,
  preConfirm: () => {
    return
  },
  didOpen: (toast: any) => {
    toast.addEventListener('mouseenter', swal.stopTimer)
    toast.addEventListener('mouseleave', swal.resumeTimer)
  }
})
