/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'manage': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" d="M8.42 19.044a2 2 0 0 1 1.867 1.283H23.25a.75.75 0 1 1 0 1.5l-12.99.002a2 2 0 1 1-1.84-2.785zm-2.635 1.282a1.997 1.997 0 0 0 .065 1.502h-2.1a.75.75 0 0 1 0-1.5l2.035-.002zM8.419 5a2 2 0 0 1 1.884 1.326l12.947.001a.75.75 0 1 1 0 1.5l-13.01.001A2 2 0 1 1 8.42 5zM5.785 6.326a1.997 1.997 0 0 0 .065 1.502h-2.1a.75.75 0 0 1 0-1.5l2.035-.002zm12.12 5.693a2 2 0 1 1-1.83 2.81L3.75 14.827a.75.75 0 0 1 0-1.5h12.278a2 2 0 0 1 1.877-1.308zm2.666 1.307l2.679.001a.75.75 0 1 1 0 1.5l-2.744.001a1.993 1.993 0 0 0 .065-1.502z" _fill="#242B33" fill-rule="evenodd"/>'
  }
})
