/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'archive': {
    width: 46,
    height: 38,
    viewBox: '0 0 46 38',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.833 10.334c.69 0 1.25.56 1.25 1.25V33.5c0 .967.784 1.75 1.75 1.75h28.5a1.75 1.75 0 0 0 1.75-1.75V11.584a1.25 1.25 0 0 1 2.5 0V33.5a4.25 4.25 0 0 1-4.25 4.25h-28.5a4.25 4.25 0 0 1-4.25-4.25V11.584c0-.69.56-1.25 1.25-1.25z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M.75 4A3.25 3.25 0 0 1 4 .75h38.167A3.25 3.25 0 0 1 45.417 4v5.583a3.25 3.25 0 0 1-3.25 3.25H4a3.25 3.25 0 0 1-3.25-3.25V4zM4 3.25a.75.75 0 0 0-.75.75v5.583c0 .415.336.75.75.75h38.167a.75.75 0 0 0 .75-.75V4a.75.75 0 0 0-.75-.75H4zM18 19.25c0-.69.56-1.25 1.25-1.25h7.667a1.25 1.25 0 0 1 0 2.5H19.25c-.69 0-1.25-.56-1.25-1.25z" _fill="#000"/>'
  }
})
