import { Model, Attr } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'

@Model()
export default class Caretaker extends ApplicationRecord {
  static jsonapiType = 'caretakers'
  static singularResource = true

  @Attr({ persist: false }) name!: string
  @Attr({ persist: false }) logo!: string
}
