import { Model, Attr, BelongsTo, HasOne } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { GiftCard, CardStock } from '@/models/index'

@Model()
export default class Sku extends ApplicationRecord {
  static jsonapiType = 'skus'

  @Attr() faceValue!: number
  @Attr() faceValueCents!: number
  @Attr() faceValueFree!: boolean
  @Attr() faceValueMin!: number
  @Attr() faceValueMax!: number
  @Attr() cardImage!: string
  @Attr() discardedAt!: Date

  @HasOne() cardStock!: CardStock
  @BelongsTo() giftCard!: GiftCard

  public notDiscarded() {
    return this.discardedAt === undefined || this.discardedAt === null
  }
}
