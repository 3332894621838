import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const numberFormats = {
  fr: {
    decimal: {
      style: 'decimal',
      separator: ' ',
      delimiter: ',',
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      separator: ' ',
      delimiter: ',',
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    }
  }
}

const i18n = new VueI18n({
  locale: 'fr',
  numberFormats: numberFormats,
})

export default i18n
