import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import Program from '@/models/Program'

@Model()
export default class User extends ApplicationRecord {
  static jsonapiType = 'customers'
  static endpoint = '/user'
  static singularResource = true

  @Attr() email!: string
  @Attr() firstname!: string
  @Attr() lastname!: string
  @Attr() password!: string
  @Attr() passwordConfirmation!: string
  @Attr() subdomain!: string
  @Attr() phoneNumber!: string
  @Attr() savingsAmount!: number
  @Attr() acceptEarnDiscounts!: boolean
  @Attr() discountsUnlocked!: boolean
  @Attr() locked!: boolean
  @Attr({ persist: false }) confirmedAt!: Date
  @Attr() businessEmail!: null | string
  @Attr() cniegCode!: null | string
  @Attr() optinNewsletter!: boolean
  @Attr() isRetired!: boolean
  @Attr() registrationStatus!: string

  @BelongsTo() program!: Program

  public getInitalName() {
    if (this.firstname && this.lastname) return `${this.firstname.charAt(0)}${this.lastname.charAt(0)}`
    return ''
  }
}
