/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'ebons': {
    width: 25,
    height: 23,
    viewBox: '0 0 25 23',
    data: '<path pid="0" d="M17.389.067l.184.046c1.459.418 2.625 1.844 2.24 3.263-.181.665-.659 1.233-1.436 1.7h3.465a3 3 0 0 1 3 3v11.445a3 3 0 0 1-3 3H3.602a3 3 0 0 1-3-3V8.076a3 3 0 0 1 3-3h3.464c-.777-.467-1.255-1.035-1.436-1.7-.385-1.42.781-2.845 2.24-3.263l.184-.046c1.526-.337 2.831.626 3.888 2.348.287.468.559 1.013.78 1.532.22-.519.492-1.064.779-1.532C14.558.693 15.863-.27 17.389.067zM11.97 10.726h-9.87v8.795a1.5 1.5 0 0 0 1.356 1.494l.144.006h8.37V10.726zm11.37 0h-9.87v10.295h8.37a1.5 1.5 0 0 0 1.494-1.355l.007-.145v-8.795zm-2.914 6.653a.75.75 0 0 1 0 1.5h-2.946a.75.75 0 0 1 0-1.5h2.946zM11.971 6.576h-8.37a1.5 1.5 0 0 0-1.493 1.356l-.007.144v1.15h9.87v-2.65zm9.87 0h-8.37v2.65h9.87v-1.15a1.5 1.5 0 0 0-1.355-1.493l-.144-.007zm-10.307-1.8c-.57-1.472-2.111-3.547-3.25-3.22-.757.216-1.341.93-1.206 1.427.199.731 1.54 1.44 4.222 1.835l.263.037zm2.374 0l-.028.08.263-.038c2.681-.395 4.023-1.104 4.222-1.835.135-.497-.449-1.21-1.205-1.428-1.14-.326-2.68 1.749-3.252 3.22z" _fill="#000" fill-rule="nonzero"/>'
  }
})
