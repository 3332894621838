/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'external_link': {
    width: 13,
    height: 13,
    viewBox: '0 0 13 13',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.55 1A.45.45 0 0 1 8 .55h4a.45.45 0 0 1 .45.45v4a.45.45 0 0 1-.9 0V1.45H8A.45.45 0 0 1 7.55 1z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M12.318.682a.45.45 0 0 1 0 .636l-5 5a.45.45 0 1 1-.636-.636l5-5a.45.45 0 0 1 .636 0z" _fill="#000"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M1.1 1.153A1.869 1.869 0 0 1 2.467.55h3.666a.45.45 0 0 1 0 .9H2.467a.97.97 0 0 0-.709.317 1.18 1.18 0 0 0-.308.804v7.857c0 .152.028.3.081.44.054.138.131.261.227.364.096.103.209.183.33.237.121.054.25.08.379.08h8.066a.934.934 0 0 0 .379-.08c.121-.054.233-.134.33-.237.095-.102.173-.226.226-.365a1.22 1.22 0 0 0 .082-.439V7.286a.45.45 0 0 1 .9 0v3.142c0 .261-.048.52-.142.764a2.037 2.037 0 0 1-.409.655c-.176.189-.387.34-.622.444a1.832 1.832 0 0 1-.744.159H2.467c-.257 0-.51-.054-.744-.159a1.914 1.914 0 0 1-.623-.444 2.034 2.034 0 0 1-.408-.655 2.12 2.12 0 0 1-.142-.764V2.571c0-.526.195-1.037.55-1.418z" _fill="#000"/>'
  }
})
