export function getSubdomain() {
  const hostname = window.location.hostname.split('.')
  if (hostname.length === 2) return 'app'

  const [subdomain, domain] = hostname
  if (domain === 'walleo')
    return subdomain || 'app'
  else if(domain === 'bemove-avantages')
    return 'bemove'
  else
    return 'app'
}