/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'search': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M10.112.021a9.504 9.504 0 0 1 9.504 9.504c0 2.306-.821 4.42-2.187 6.065l4.22 4.22a.807.807 0 1 1-1.141 1.14l-4.21-4.21a9.466 9.466 0 0 1-6.186 2.289 9.504 9.504 0 1 1 0-19.008zm0 1.5a8.004 8.004 0 1 0 0 16.008 8.004 8.004 0 0 0 0-16.008z" _fill="#000" fill-rule="nonzero"/>'
  }
})
