import { Vue } from 'vue-property-decorator'
import VueQr from 'vue-qr'

export const mobileLyfComponent = Vue.component('mobileLyf', {
  data() {
    return {
      googleplay: require('@/assets/utils/googleplay_btn.png'),
      appstore: require('@/assets/utils/appstore_btn.png'),
      lyfIcon: require('@/assets/logo/lyf-pay-icon.svg'),
      universalLink: `https://${process.env.VUE_APP_LYFPAY_UNIVERSAL_ENDPOINT}/shops/${process.env.VUE_APP_LYFPAY_UNIVERSAL_SHOP_UID}/features/${process.env.VUE_APP_LYFPAY_UNIVERSAL_FEATURE}`
    }
  },
  template: `
    <div style="display: flex; padding: 30px 0; gap: 30px">
      <div style="display: flex; flex-direction: column; align-items: start">
        <div style="display: flex; align-items: center">
          <svg-icon name="warning" width="60" height="60" style="color: #fe6b70"></svg-icon>
          <h3 style="text-align: left; margin-left: 20px; color: #fe6b70">Êtes-vous sûr de vouloir continuer<br>à naviguer en mode web ?</h3>
        </div>
        <div style="margin: auto 0">
          <h4 style="margin-top: 10px; text-align: left">
            Pour une expérience optimale nous vous recommandons d’utiliser notre service eBons depuis l’application Lyf.
          </h4>
          <h4 style="margin-top: 20px; text-align: left">
            Vous n’avez plus qu’à scanner le QR code ici présent pour accéder à l’application Lyf !
          </h4>
        </div>
        <v-btn text color="#00A0E4" class="btn-close" style="text-transform: none; padding: 0; font-weight: 400">Continuer quand même</v-btn>
      </div>
      <v-divider vertical></v-divider>
      <div style="display: flex; flex-grow: 1">
        <div style="display: flex; flex-direction: column; width: 250px; align-items: center; margin: 0 auto">
          <vue-qr :text="universalLink" :size="150" :logo-src="lyfIcon" :logo-margin="5" :margin="0"></vue-qr>
          <div style="text-align: center; font-size: 11px; margin-bottom: 50px; width: 150px; margin-top: 15px">
            Scanner ce QR Code depuis votre smartphone pour ouvrir l'application Lyf Pay
          </div>
          <a href="https://play.google.com/store/apps/details?id=com.fivory.prod&hl=fr&gl=US">
            <img :src="googleplay" style="width: 150px" alt="Get in on Goole play" />
          </a>
          <a href="https://apps.apple.com/fr/app/lyf-pay/id824967438">
            <img :src="appstore" style="margin-top: 10px; width: 150px" alt="Get in on Apple store" />
          </a>
        </div>
      </div>
    </div>`,
  components: { VueQr }
})
