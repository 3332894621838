
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'SplashScreen'
})
export default class extends Vue {
  @Prop({ required: true }) show!: boolean

  private userStore = useUserStore()
  private showSplashScreen = false

  @Watch('show')
  startTimer(canShow: boolean) {
    if (canShow) {
      this.showSplashScreen = true

      setTimeout(() => {
        this.showSplashScreen = false
        this.$emit('close')
      }, 2500)
    }
  }

  get program(): any {
    return this.userStore.model.program
  }

  get showWalleoLogo(): any {
    return this.userStore.model.program.walleoLogoEnabled
  }

  get programId(): any {
    return this.program?.id
  }

  get walleoLogo() {
    return require('@/assets/logo/logo-walleo_powered.png')
  }

  get bgColor(): string {
    return this.program?.splashScreenBackgroundColor || '#fffff'
  }

  get logoDisplayed() {
    return this.program?.splashScreenLogo ? this.program?.splashScreenLogo : this.program?.logo ? this.program.logo : this.walleoLogo
  }
}
