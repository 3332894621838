/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'shop': {
    width: 600,
    height: 600,
    viewBox: '0 0 600 600',
    data: '<path pid="0" d="M596.636 209.219L484.72 49.114a18.65 18.65 0 0 0-15.288-7.965H136.787a18.63 18.63 0 0 0-15.008 7.58L3.643 208.835A18.673 18.673 0 0 0 0 219.907c0 36.926 26.935 67.617 62.17 73.643v207.27c0 31.995 26.032 58.028 58.028 58.028h364.253c31.995 0 58.023-26.033 58.023-58.028V292.3c29.858-7.661 52.596-33.271 55.834-64.638a18.65 18.65 0 0 0-1.672-18.443zM441.458 521.548h-77.72V362.221h77.72v159.327zm63.708-20.723c.008 11.429-9.294 20.723-20.708 20.723h-5.695V343.569c0-10.301-8.35-18.654-18.654-18.654H345.084c-10.301 0-18.654 8.35-18.654 18.654v177.979H120.198c-11.43 0-20.723-9.297-20.723-20.723V290.476a75.09 75.09 0 0 0 31.46-21.201c13.717 15.582 33.805 25.422 56.139 25.422 22.333 0 42.422-9.843 56.138-25.422 13.717 15.582 33.805 25.422 56.138 25.422 22.341 0 42.429-9.843 56.146-25.422 13.716 15.582 33.805 25.422 56.138 25.422 22.334 0 42.422-9.843 56.139-25.422 9.73 11.054 22.713 19.136 37.393 22.949v208.601zm18.755-243.431c-20.667 0-37.487-16.817-37.487-37.487 0-10.301-8.35-18.653-18.654-18.653-10.302 0-18.654 8.35-18.654 18.653 0 20.674-16.818 37.487-37.487 37.487s-37.487-16.817-37.487-37.487c0-10.301-8.35-18.653-18.654-18.653-10.301 0-18.654 8.35-18.654 18.653 0 20.674-16.817 37.487-37.491 37.487s-37.487-16.817-37.487-37.487c0-10.301-8.35-18.653-18.654-18.653s-18.654 8.35-18.654 18.653c0 20.674-16.818 37.487-37.487 37.487s-37.487-16.817-37.487-37.487c0-10.301-8.35-18.653-18.653-18.653-10.304 0-18.654 8.35-18.654 18.653 0 20.674-16.818 37.487-37.487 37.487-18.777 0-34.371-13.871-37.075-31.909L146.199 78.457h313.518l101.509 145.214c-1.897 18.913-17.906 33.723-37.305 33.723z" _fill="#000"/><path pid="1" d="M274.894 324.918H147.668c-10.302 0-18.654 8.35-18.654 18.654v116.586c0 10.302 8.35 18.654 18.654 18.654h127.223c10.302 0 18.654-8.35 18.654-18.654V343.572c0-10.304-8.35-18.654-18.651-18.654zM256.24 441.504h-89.918v-79.281h89.918v79.281z" _fill="#000"/>'
  }
})
