import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Sku } from '@/models/index'

@Model()
export default class IncomingCard extends ApplicationRecord {
  static jsonapiType = 'incoming_cards'

  @Attr() amount!: number

  @BelongsTo() sku!: Sku
}
