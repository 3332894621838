import { Model, Attr, HasMany } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { GiftCard } from '@/models/index'

@Model()
export default class MultiChoiceGiftCard extends ApplicationRecord {
  static jsonapiType = 'multi_choice_gift_cards'

  @Attr() id!: string
  @Attr() name!: string
  @Attr() eligibleGiftCardsCount!: number
  @Attr() description!: string
  @Attr() createdAt!: string
  @Attr() logo!: string
  @Attr() bannerImage!: string

  @HasMany() giftCards!: GiftCard[]
}
